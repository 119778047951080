.errorBoundaryTitle {
  color: black;
  font-size: 3rem;
  text-align: center;
}

.errorBoundaryBody {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
  margin: 2rem;
}

.errorBoundaryAuthor{
  text-align: center;
}