.rbc-toolbar {
  //display: none;
}

.meetingRoomDetailList {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.meetingRoomDetailList > div {
  flex-grow: 1;
  cursor: pointer;
  font-size: 1.3rem;
  text-align: center;
  color: #888;
  border-bottom-color: #aaa;
  border-bottom-width: 4px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border-bottom-style: solid;
}

.meetingRoomDetailList > div.active {
  font-weight: bold;
  color: #3C4B64;
  background-color: #e0e0e0;
  border-bottom-color: #2eb85c;
}