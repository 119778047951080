// Here you can add other styles

.cardPanel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding: 0.3rem;
  width: 100%;
}

.closeErrorButton {
  color: #fff;
  width: 15px;
  height: 15px;
}

.page-item.active .page-link {
  background-color: #2eb85c;
  border-color: #2eb85c;
}

.page-link {
  color: #555;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: #2eb85c;
  color: #fff;
}

.nav-tabs .nav-link {
  color: #768192;
  background-color: #ebedef;
  border-color: #c4c9d0 #c4c9d0
}

.nav-tabs .nav-link:hover {
  border-color: #aaa;
}

.columnFilterSlotSelection {
  width: 100%;
  z-index: 1000;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #768192;
  border-radius: 0.2rem;
  text-align: left;
  list-style: none;
  border-color: #d8dbe0;
}

.max-width {
  width: 100%;
}

a:hover {
  color: #2eb85c;
}

.list-group-item {
  padding: 0.45rem 1.25rem;
}

.success-on-hover:hover {
  color: #2eb85c;
}

.dropdown-item:focus {
  background-color: #2eb85c;
}

blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.smallInfo {
  font-size: 0.75rem;
  color: #888;
}

.status-online {
  float: left;
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  shape-outside: circle();
  clip-path: circle();
  background: radial-gradient(circle at 8px 8px, #26ff00, #3dba01);
}

.status-offline {
  float: left;
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  shape-outside: circle();
  clip-path: circle();
  background: radial-gradient(circle at 8px 8px, #ff3e3e, #fca9a9);
}

.fulltext-highlight {
  background-color: #FF9;
  border-radius: 20%;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background: radial-gradient(circle at 0px 8px, #2eb85c, #2eb85c);
}

#reservation-overview {
  background-color: #888;
}

.image-gallery-thumbnail-image {
  width: 50px !important;
  height: 35px;
  aspect-ratio: unset;
}

.image-gallery-thumbnail-inner {
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
}


// SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR
// SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR
// SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR

.c-sidebar[class*="bg-"] {
  border-color: rgba(0, 0, 21, 0.1);
}

.c-sidebar-close {
  position: absolute;
  width: 56px;
  height: 56px;
  background: transparent;
  border: 0;
}

.c-sidebar {
  color: #76b703;
  background: #fff;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #d8dbe0;
}


.c-sidebar .c-sidebar-close {
  color: #76b703;
}

.c-sidebar .c-sidebar-brand {
  background: #ebedef;
}

.c-sidebar .c-sidebar-header {
  background: #ebedef;
}

//.c-sidebar .c-sidebar-form .c-form-control {
//  color: #fff;
//  background: rgba(0, 0, 21, 0.1);
//  border: 0;
//}
//
//.c-sidebar .c-sidebar-form .c-form-control::placeholder {
//  color: rgba(255, 255, 255, 0.7);
//}

.c-sidebar .c-sidebar-nav-title {
  color: #555;
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #555;
  background: transparent;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #555;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #76b703;
  background: #fafafa;
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #76b703;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #76b703;
    background: #fafafa;
  }
  .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #76b703;
  }
  .c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after, .c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2376b703' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23555' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: #fafafa;
  color: #555
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #555;
}

.c-sidebar .c-sidebar-footer {
  background: #ebedef;
}

.c-sidebar .c-sidebar-minimizer {
  background-color: #fff;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #d8dbe0;
}

.c-sidebar .c-sidebar-minimizer:hover {
  background-color: #fff;
}

.c-sidebar .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23bbb' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23bbb' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
  background: #eaeaea;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #76b703;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
  color: #555;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  background: #eaeaea;
  //color: #76b703;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #eaeaea;
  color: #76b703;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
  background: #fff;
  color: #555;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-items > .c-sidebar-nav-item > .c-active {
  color: #76b703;
}


html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link, html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
  padding-left: 10px;
}


.qlbt-operation-menu {
  z-index: 99999999;
  padding: 1rem;
  background-color: #fefefe;
  border-width: 1px;
  border-style: solid;
  border-color: #f5f5f5;
  border-radius: 1rem;
}

.qlbt-operation-menu-item {
  cursor: pointer;
}


.qlbt-operation-menu-item:hover {
  //background-color: #fbfbfb;
}

.qlbt-operation-menu-icon {
  margin-right: 0.5rem;
}

.flex-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-col > .left {
  flex-shrink: 0;
}

.flex-col > .center {
  flex-grow: 1;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.flex-col > .right {
  flex-shrink: 0;
}

tr:nth-child(even) {
  background-color: #f3f3f3;
}

a {
  color: #76b703;
  //text-decoration: underline;
}

.container-fluid {
  //background-color: yellow;
  min-height: 100%;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  //background-color: yellow;
}

.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}

//.ql-code-block{
//  background-color: #eee;
//  border-color: #ccc;
//  border-width: 1px;
//  padding: 0.5rem;
//  font-family: monospace;
//  font-size: 0.8rem;
//  border-style: solid;
//}

.ql-tooltip .ql-editing {
  z-index: 100000000;
}

tr {
  cursor: pointer;
}

.tabButtons {
  display: flex;
  flex-direction: row;
  color: #555;
}

.tabButton {
  cursor: pointer;
  text-align: center;
  line-height: 1.8rem;
  padding: 0.5rem;
  min-width: 5rem;
}

.tabButtonActive {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #76b703;
}

select:focus {
  border-width: 1px;
  border-color: #333;
  outline: none;
}

//.nav-tabs .nav-link{
//  background-color: white;
//  color: #555;
//  border-style: none;
//  border-radius: 0;
//  margin-bottom: 1px;
//}
//
//.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
//  background-color: white;
//  color: #555;
//  border-left-style: none;
//  border-top-style: none;
//  border-right-style: none;
//  border-bottom-style: solid;
//  border-bottom-width: 4px;
//  border-bottom-color: #76b703;
//}

table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid #E6E6E6;
}

th td {
  padding: 0.3rem
}

table th, table td {
  padding: 0.3rem;
}

table th, table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}

table th, table td {
  font-size: 12px;
  padding: 1px 1rem 1px 1rem;

  color: #555;
  border-bottom: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;

  white-space: nowrap;
  -moz-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

table thead th {
  color: #555;
  height: 2rem;
  border-top: none;
  border-bottom: none;
  border-bottom: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
}

table th {
  position: relative;
}


.smaller {
  font-size: 0.5rem;
}

.tracy {
  text-align: center;
  margin: 1rem;
}

.tracyButton {
  background-color: #c02e2e;
  cursor: pointer;
  padding-right: 3rem;
  padding-left: 3rem;

  color: white;
  text-align: center;
}

.table-danger{
  background-color: red;
}

.editableTableCell:hover{
  color: #76b703;
}
