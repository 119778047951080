.tablePanelButton {
  color: #595959;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 0.5rem;
}

.tablePanelButtonIcon {
  color: #76b703;
}
