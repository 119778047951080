.info-box {
  position: absolute;
  bottom: 0.3rem;
  z-index: 1000000;
  left: 1%;
  width: 98%;
  border-width: 1px;
  border-style: solid;
  height: 2.8rem;
  line-height: 2.4rem;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.info-box-next {
  position: absolute;
  height: 2.8rem;
  bottom: 2.8rem;
  width: 8.4rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #a4ffaf;
}

.loader {
  border: 2px solid white; /* Light grey */
  border-top: 2px solid rgb(30, 93, 126); /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.info-box-error {
  @extend .info-box;
  border-color: rgb(194, 26, 39);
  background-color: rgba(214, 39, 50, 1);
}

.info-box-loading {
  @extend .info-box;
  color: rgb(30, 93, 126);
  border-color: rgb(135, 217, 199);
  background-color: rgb(175, 255, 238);
}

.info-box-close {
  color: white;
  height: 1.8rem;
  cursor: pointer;
  line-height: 1.8rem;
  font-size: 0.9rem;
  border-width: 1px;
  border-style: solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-color: #fff;
}

.info-box-error-close {
  @extend .info-box-close;
}


.info-box-loading-close {
  @extend .info-box-close;
  border-color: rgb(30, 93, 126);
  color: rgb(30, 93, 126);
}
